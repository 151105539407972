<!--
 * @Description: 后台图片上传组件,后期要改为传到阿里云oss  底部可以直接输入网址  上传图片  上传文件
 * import FaImageUpload from 'faim/dist/components/ImageUpload/index.vue'
 * import globalConfigForFaImageUpload from '@/faim/ImageUpload/globalConfig'
 * \packages\mall-cook-platform\src\faim\ImageUpload
 * 上传组件地址
-->
<template>
  <config-item :label='label'>
    <FaImageUpload
      v-model="mValue"
      v-bind="mOptions"
      :maxCount="1"
    />
	<el-input v-model="mValue" v-bind="mOptions" placeholder='请输入图片地址' size='small'></el-input>
  </config-item>
  
</template>

<script>
import schemaMixin from "@/mixin/schemaMixin";

export default {
  name: "SchemaUpload",

  mixins: [schemaMixin],
};
</script>
