import Vue from 'vue'
import global from '@/config/global'

export default async (file) => new Promise((resolve, reject) => {
	//图片上传  后期改为阿里云oss
	console.log('图片上传---->', global.baseApi);
	console.log('图片上传---->', file);
	//判断尺寸 
	if (file.size <= 400000) {
		Vue.prototype.$POST.upload(global.baseApi + 'upload', ({
			file,
		}), {
			baseURL: '',
			timeout: 20000,
		}).then(res => {
			console.log('后期改为阿里云oss,图片上传返回---->', res.data);
			resolve(res.data)
		}).catch(e => {
			reject(e)
		})
	} else {
		console.log('图片上传---->尺寸超大');
		//reject({ message: '图片文件尺寸超过500K,请压缩后再上传', status: 10001 })
		resolve('https://kdz-2023-photo-001.oss-cn-shanghai.aliyuncs.com/upload/202502/13/892994-202502131419034318.png'); //返回默认图片
		//尺寸超过500k

	}
})